import React, { FC } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label } from "reactstrap";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useAppDispatch } from "../../../../hooks/redux";
import { setShowNotification } from "../../../../store/reducers/widgets/WidgetsSlice";

interface IModalCreateCenterProps {
    isOpen: boolean;
    toggle: () => void;
    onSave: (data: { name: string }) => void;
    initialData?: { id: number; name: string };
}

const ModalCreateCenter: FC<IModalCreateCenterProps> = ({ isOpen, toggle, onSave, initialData }) => {
    const dispatch = useAppDispatch();

    return (
        <Modal
            id="ModalCreateCenter"
            isOpen={isOpen}
            toggle={toggle}
            modalClassName="zoomIn"
            tabIndex={-1}
            centered
        >
            <ModalHeader toggle={toggle} className="p-3 bg-soft-primary" id="createCenterModalLabel">
                {initialData ? 'Редактировать центр тестирования' : 'Создать центр тестирования'}
            </ModalHeader>
            <ModalBody>
                <Formik
                    initialValues={{
                        name: initialData?.name || '',
                    }}
                    validationSchema={Yup.object({
                        name: Yup.string().required('Название центра обязательно'),
                    })}
                    onSubmit={(values) => {
                        onSave({
                            name: values.name,
                        });
                        dispatch(setShowNotification({
                            type: "success",
                            message: initialData ? 'Центр тестирования успешно обновлён!' : 'Центр тестирования успешно создан!',
                            show: true
                        }));
                        toggle();
                    }}
                    enableReinitialize
                >
                    {({ errors, touched }) => (
                        <Form>
                            <FormGroup className="mb-3">
                                <Label for="name">Название</Label>
                                <Field
                                    name="name"
                                    type="text"
                                    className={`form-control ${errors.name && touched.name ? 'is-invalid' : ''}`}
                                    placeholder="Введите название центра"
                                />
                                {errors.name && touched.name && (
                                    <div className="invalid-feedback">{errors.name}</div>
                                )}
                            </FormGroup>

                            <div className="hstack gap-2 justify-content-end mt-1">
                                <Button color="primary" type="submit">
                                    Сохранить
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalBody>
        </Modal>
    );
};

export default ModalCreateCenter;