export enum ConstRoutes {
    LOGIN = "/login",
    ADMIN_SETTINGS_USER = "/admin/settings/user",
    ADMIN_SETTINGS_CITY = "/admin/settings/city",
    ADMIN_SETTINGS_COUNTRY = "/admin/settings/country",
    ADMIN_SETTINGS_ORGANIZATION = "/admin/settings/organization",
    ADMIN_SETTINGS_POSITION = "/admin/settings/position",
    ADMIN_SETTINGS_TERM = "/admin/settings/term",
    ADMIN_SETTINGS_PORTFOLIO = "/admin/settings/portfolio",
    DEPARTMENT_SETTINGS_CABINET = "/department/settings/cabinet",
    DEPARTMENT_SETTINGS_TYPE_CABINET = "/department/settings/cabinet/type",
    DEPARTMENT_SETTINGS_CATEGORY_OVZ = "/department/settings/category-ovz",
    DEPARTMENT_SETTINGS_TYPE_GROUP = "/department/settings/group/type",
    DEPARTMENT_SETTINGS_TYPE_DEVIATION = "/department/settings/deviation/type",
    DEPARTMENT_SETTINGS_SUBDIVISION = "/department/settings/subdivision",
    DEPARTMENT_SETTINGS_REGION = "/department/settings/region",
    DEPARTMENT_SETTINGS_RECOMMENDED_ACTIVE = "/department/settings/recommended-active",
    DEPARTMENT_SETTINGS_GROUP = "/department/settings/group",
    DEPARTMENT_SETTINGS_GROUP_HEALTH = "/department/settings/group-health",
    DEPARTMENT_SETTINGS_LIMIT_HEALTH = "/department/settings/limit-health",
    DEPARTMENT_SETTINGS_EMPLOYEE = "/department/settings/employee",
    DEPARTMENT_SETTINGS_DO_GROUP = "/department/settings/do-group",
    DEPARTMENT_SETTINGS_CHILDREN = "/department/settings/children",
    DEPARTMENT_SETTINGS_PARENT = "/department/settings/parent",
    DEPARTMENT_SETTINGS_DEVIATION = "/department/settings/deviation",
    DEPARTMENT_SETTINGS_CHILDREN_MOVEMENT_REGISTER = "/department/settings/children-mr",
    DEPARTMENT_ATTENDANCE_IN_ORGANIZATION = "/attendance_in_organizations",
    DEPARTMENT_DEVIATIONS_CHILDREN_IN_ORGANIZATION = "/deviations_children_in_organization",
    DEPARTMENT_RECOMMENDED_LESSONS_IN_ORGANIZATIONS = "/recommended_lessons_in_organizations",
    DEPARTMENT_OVZ_DISABLED_PERSONS_PMPK = "/ovz_disabled_person_pmpk",
    DEPARTMENT_OVZ_DISABLED_PERSONS_PMPK_YEAR = "/ovz_disabled_person_pmpk_year",
    DEPARTMENT_CERTIFICATE_OVZ_DISABLED_PERSONS = "/certificate_ovz_disabled_person",
    DEPARTMENT_OVZ_DISABLED_PERSONS = "/ovz_disabled_persons",
    DEPARTMENT_PHYSICAL_EDUCATION = "/physical_education",
    DEPARTMENT_GROUP_FOCUSES = "/group_focuses",
    DEPARTMENT_ADDITIONAL_EDUCATION_IN_PRE_SCHOOL = "/additional_education_in_pre_school",
    DEPARTMENT_GROUP_HEALTH = "/group_health",
    DEPARTMENT_TALENTED_CHILDREN = "/talented_children",
    DEPARTMENT_ACHIEVEMENT_CHILDREN = "/achievement_children",
    DEPARTMENT_ACHIEVEMENT_CHILDREN_DEVIATION = "/achievement_children_deviation",
    DEPARTMENT_PROGRAM = "/program",
    DEPARTMENT_ATTENDANCE = "/attendance",
    DEPARTMENT_TEACHERS = "/teachers",
    DEPARTMENT_VSK = "/vsk",
    DEPARTMENT_SAFETY = "/safety",
    DEPARTMENT_APR = "/apr",
    DEPARTMENT_COMPETITIONS = "/competitions",
    DEPARTMENT_COURSES = "/courses",
    DEPARTMENT_MTB = "/mtb",
    DEPARTMENT_SOCIAL = "/social",
    DEPARTMENT_NUTRITION = "/nutrition",
    DEPARTMENT_NUTRITION_DIET = "/nutrition_diet",
    DEPARTMENT_PEDAGOGICAL_CONTROL = "/pedagogical_control",
    DEPARTMENT_MEDICAL_CONTROL = "/medical_control",
    DEPARTMENT_ADMINISTRATIVE_CONTROL = "/administrative_control",
    DEPARTMENT_METHODOLOGICAL_WORK = "/methodological_work",
    MAIN_PAGE = "/main",
    MAIN_PAGE_DO = "/main/do",
    LEVEL_EVOLUTION_CHILDREN = "/level_evolution_children",
    OPERATIONAL_CONTROL = "/operational_control",
    EMPLOYEE_PERFOMANCE = "/employee/prefomance",
    HELP_PAGE = "/help",
    SETTINGS = "/settings",
    PARENT_CALENDAR = "/calendar",
    REGISTER_DEFECT_EQUIPMENT = "/register/defect/equipment",
    PURCHASE_MATERIALS = "/purchase_materials",
    PARENT_CHILDREN_ATTENDANCE = "/parent/children/attendance",
    PARENT_CHILDREN_ATTENDANCE_PAID = "/parent/children/attendance/paid",
    PARENT_CHILDREN_EVOLUTION = "/parent/children/evolution",
    PARENT_CHILDREN_FOOD_RATION = "/parent/children/food-ration",
    PARENT_CHILDREN_CASH_ACCOUNTING  = "/parent/children/cash-accounting",
    PARENT_CHILDREN_PORTFOLIO = "/parent/children/portfolio",
    PARENT_CHILDREN_PORTFOLIO_MORE = "/parent/children/portfolio/more/:id",
    PARENT_CHILDREN_NEWS = "/parent/news",
    PARENT_CHILDREN_NEWS_MORE = "/parent/news/more/:id",
    NEWS_CHILDREN_NEWS_MORE = "/news/more/:id",
    CHILDREN_PORTFOLIO = "/children/portfolio",
    CHILDREN_PORTFOLIO_UPDATE = "/children/portfolio/update/:id",
    CHILDREN_PORTFOLIO_ADD = "/children/portfolio/add",
    PARENT_PORTFOLIO_LIST = "/parent/portfolio",
    PARENT_PORTFOLIO_MORE = "/parent/portfolio/more/:id",
    PROFILE_GUEST = "/profile/:id",
    PROFILE = "/profile",
    GROUP_NEWS = "/news",
    GROUP_NEWS_ADD = "/news/add",
    GROUP_NEWS_UPDATE = "/news/update/:id",
    DAILY_REPORT = "/daily_report",
    CHAT = '/chat',
    FEEDBACK = '/feedback',
    TASK_ME = '/task/my',
    TASK_FROM_ME = '/task/from_me',
    MY_EVENT = '/event/my',
    SEMINAR = '/seminar',
    CATERING_ORGANIZERS = '/catering_organizers',
    CATERING_ORGANIZERS_ADD = '/catering_organizers/add',
    TYPES_NUTRITION = '/types_nutrition',
    SCHOOLS = '/schools',
    SEMINAR_ADMIN = '/seminar_admin',
    STUDENTS = '/students',
    HOME_EDUCATION = '/home_education',
    ASSORTMENT = '/assortment',
    COMPLEX_MENU = '/complex_menu',
    BALANCE = '/balance',
    CASH_MOVEMENT = '/cash_movement',
    BUFFET = '/buffet',
    REQUEST_FOR_NUTRITION = '/request_for_nutrition',
    CHILDREN_AND_PARENT = '/children_and_parent',
    CLASSES_AND_TEACHER = '/classes_and_teacher',
    SUBMISSION_DOCUMENTS = '/submission_documents/:id/:number',
    ARM_EDUCATOR = '/arm_educator',
    ARM_PARENT_CASH_ACCOUNTING = '/arm_parent/cash_accounting',
    ARM_PARENT_FOOD_RATION = '/arm_parent/food_ration',
    ARM_PARENT_BUFFET = '/arm_parent/buffet',
    ARM_PARENT_MY_PAYMENTS = '/arm_parent/my_payments',
    TYPE_PLANNED_MENU = '/type_planned_menu',
    BENEFIT = '/benefit',
    STATUSES = '/statuses',
    IMPORTS = '/imports',
    COPY_BENEFITS = '/copy_benefits',
    SETTINGS_USER = '/arm/settings_user',
    REPORT = '/reports',
    AGE_CATEGORIES = '/age_categories',
    SHIFT = '/shift',
    ADMIN_SETTINGS_PAGE = '/admin/settings/page',
    STATISTICS_LOGIN = '/statistics_login',
    STATISTICS_LOGIN_KSHP = '/statistics_login_kshp',
    STATISTICS_LOGIN_KSHP_ARM_AP = '/statistics_login_kshp_arm_ap',
    STATISTICS_LOGIN_KSHP_ARM_OP = '/statistics_login_kshp_arm_op',
    GAME = '/games/create',
    GAMES = '/games',
    PLAY_GAME = '/game/:id',
    PLAY_GAME_PARENT = '/game/parent/:id',
    GAMES_TEACHER = '/games/teacher',
    GAMES_UPDATE = '/games/update/:id',
    MEASURE_TEMPERATURES = '/measure_temperatures',
    JOURNAL_VENTILATION = '/journal_ventilation__room',
    JOURNAL_GENERAL_CLEANING = '/journal_general_cleaning',
    JOURNAL_MORNING_FILTER_EMPLOYEES = '/journal_morning_filter_employees',
    JOURNAL_MORNING_FILTER_CHILDREN = '/journal_morning_filter_children',
    JOURNAL_REPLACEMENT_BED_LINER = '/journal_replacement_bed_linen',
    JOURNAL_MEASURING_TEMPERATURE_BODY = '/journal_measuring_temperature_body',
    JOURNAL_ANTISEPTIC_TABLEWARE = '/journal_antiseptic_tableware',
    JOURNAL_ANTISEPTIC_SURFACE = '/journal_antiseptic_surface',
    JOURNAL_ANTISEPTIC_ROOM = '/journal_antiseptic_room',
    JOURNAL_BACTERICIDAL_LAMPS = '/journal_bactericidal_lamps',
    JOURNAL_CLEANING_DISINFECTION = '/journal_cleaning_disinfection',
    GAME_PLAY_PRIVATE = '/game/play/private/:id',
    GAME_STATISTICS = '/game/stats/:id',
    EDUCATION_POSITION = '/education/position',
    EDUCATION_TYPE_OF_EDUCATION = '/education/type_education',
    EDUCATION_QUALIFICATION_LEVELS = '/education/qualification_levels',
    EDUCATION_TYPE_OF_EVENTS= '/education/type_events',
    EDUCATION_DIRECTION_EDUCATION= '/education/direction_education',
    EDUCATION_REASONS_INSPECTIONS= '/education/reasons_inspections',
    EDUCATION_TYPE_NDT= '/education/type_NDT',
    EDUCATION_PERIODICITY_EVENTS= '/education/periodicity_events',
    EDUCATION_TYPE_WORK_PERMITS= '/education/type_work_permits',
    EDUCATION_MAIN='/education/main',
    EDUCATION_PLANING='/education/planning',
    EDUCATION_CREATE_TEST_TASKS='/education/create_test_tasks/:testId',
    EDUCATION_TESTS_PAGE='/education/test_page',
    EDUCATION_EDUCATION_PAGE='/education/education_page',
    EDUCATION_CREATE_EDUCATION_CONTENT='/education/create_education_content/:educationId',
    EDUCATION_CREATE_TESTING_CENTER='/education/testing_center/:centerId',


}