import {ConstRoutes} from "./constRoutes";
import Login from "../pages/Login";
import {JSX} from "react";
import SettingsUser from "../pages/admin/SettingsUser";
import SettingsCity from "../pages/admin/SettingsCity";
import SettingsCountry from "../pages/admin/SettingsCountry";
import SettingsOrganization from "../pages/admin/SettingsOrganization";
import SettingsPosition from "../pages/admin/SettingsPosition";
import SettingsTerm from "../pages/admin/SettingsTerm";
import SettingsCabinet from "../pages/department/SettingsCabinet";
import SettingsTypeCabinet from "../pages/department/SettingsTypeCabinet";
import SettingsCategoryOvz from "../pages/department/SettingsCategoryOvz";
import SettingsTypeGroup from "../pages/department/SettingsTypeGroup";
import SettingsTypeDeviation from "../pages/department/SettingsTypeDeviation";
import SettingsSubdivision from "../pages/department/SettingsSubdivision";
import SettingsRegion from "../pages/department/SettingsRegion";
import SettingsRecommendedActive from "../pages/department/SettingsRecommendedActive";
import SettingsGroup from "../pages/department/SettingsGroup";
import SettingsGroupHealth from "../pages/department/SettingsGroupHealth";
import SettingsLimitHealth from "../pages/department/SettingsLimitHealth";
import SettingsEmployee from "../pages/department/SettingsEmployee";
import SettingsDoGroup from "../pages/department/SettingsDoGroup";
import SettingsChildren from "../pages/department/SettingsChildren";
import SettingsParent from "../pages/department/SettingsParent";
import SettingsDeviation from "../pages/department/SettingsDeviation";
import SettingsChildrenMovementRegister from "../pages/department/SettingsChildrenMovementRegister";
import MainPage from "../pages/MainPage";
import HelpPage from "../pages/HelpPage";
import Settings from "../pages/Settings";
import Calendar from "../pages/Calendar";
import RegisterDefectEquipment from "../pages/RegisterDefectEquipment";
import ChildrenAttendance from "../pages/parent/ChildrenAttendance";
import ChildrenAttendancePaid from "../pages/parent/ChildrenAttendancePaid";
import ChildrenEvolution from "../pages/parent/ChildrenEvolution";
import ChildrenFoodRation from "../pages/parent/ChildrenFoodRation";
import ChildrenCashAccounting from "../pages/parent/ChildrenCashAccounting";
import ProfileGuest from "../pages/ProfileGuest";
import MyProfile from "../pages/MyProfile";
import ChildrenPortfolio from "../pages/parent/ChildrenPortfolio";
import ChildrenPortfolioMore from "../pages/parent/ChildrenPortfolioMore";
import KindergartenChildrenPortfolio from "../pages/Kindergarten/KindergartenChildrenPortfolio";
import KindergartenChildrenPortfolioMore from "../pages/Kindergarten/KindergartenChildrenPortfolioMore";
import KindergartenChildrenPortfolioAdd from "../pages/Kindergarten/KindergartenChildrenPortfolioAdd";
import News from "../pages/news/News";
import NewsAdd from "../pages/news/NewsAdd";
import NewsUpdate from "../pages/news/NewsUpdate";
import ParentNews from "../pages/parent/ParentNews";
import MainPaidService from "../pages/MainPaidService";
import ParentNewsMore from "../pages/parent/ParentNewsMore";
import EmployeePerfomance from "../pages/EmployeePerfomance";
import DailyReport from "../pages/DailyReportPage";
import Chat from "../pages/Chat";
import MyTask from "../pages/DocumentFlow/MyTask";
import FromMyTask from "../pages/DocumentFlow/FromMyTask";
import MyEvent from "../pages/DocumentFlow/MyEvent";
import AttendanceInOrganizations from "../pages/department/AttendanceInOrganizations";
import DeviationsChildrenInOrganization from "../pages/department/DeviationsChildrenInOrganization";
import RecommendedLessonsInOrganizations from "../pages/department/RecommendedLessonsInOrganizations";
import OVZDisabledPersonPMPK from "../pages/department/OVZDisabledPersonPMPK";
import OVZDisabledPersonPMPKYear from "../pages/department/OVZDisabledPersonPMPKYear";
import CertificateOvzDisabledPersons from "../pages/department/CertificateOvzDisabledPersons";
import OvzInv from "../pages/department/OvzInv";
import CountChildrenPhysicalEducation from "../pages/department/CountChildrenPhysicalEducation";
import GroupFocuses from "../pages/department/GroupFocuses";
import AdditionalEducationInPreSchool from "../pages/department/AdditionalEducationInPreSchool";
import GroupHealth from "../pages/department/GroupHealth";
import TalentedChildren from "../pages/department/TalentedChildren";
import AchievementsChildren from "../pages/department/AchievementsChildren";
import AchievementsChildrenDeviations from "../pages/department/AchievementsChildrenDeviations";
import ProgramsOOPDOY from "../pages/department/ProgramsOOPDOY";
import AttendanceInOrganizationDOYKindergarten from "../pages/department/AttendanceInOrganizationDOYKindergarten";
import Teachers from "../pages/department/Teachers";
import VSK from "../pages/department/VSK";
import Safety from "../pages/department/Safety";
import APR from "../pages/department/APR";
import Competitions from "../pages/department/Competitions";
import Courses from "../pages/department/Courses";
import MTB from "../pages/department/MTB";
import Social from "../pages/department/Social";
import MethodologicalWork from "../pages/department/MethodologicalWork";
import Nutrition from "../pages/department/Nutrition";
import NutritionDiet from "../pages/department/NutritionDiet";
import PedagogicalControl from "../pages/department/PedagogicalControl";
import MedicalControl from "../pages/department/MedicalControl";
import AdministrativeControl from "../pages/department/AdministrativeControl";
import OperationalControl from "../pages/OperationalControl";
import Seminar from "../pages/Seminar";
import CateringOrganizers from "../pages/KShP/CateringOrganizers";
import AddCateringOrganizers from "../pages/KShP/AddCateringOrganizers";
import TypesNutrition from "../pages/KShP/TypesNutrition";
import Schools from "../pages/KShP/Schools";
import SeminarAdmin from "../pages/admin/SeminarAdmin";
import Students from "../pages/KShP/Students";
import HomeEducation from "../pages/KShP/HomeEducation";
import Assortment from "../pages/KShP/Assortment";
import ComplexMenu from "../pages/KShP/ComplexMenu";
import Balance from "../pages/KShP/Balance";
import CashMovement from "../pages/KShP/CashMovement";
import SubmissionDocuments from "../pages/SubmissionDocuments";
import RequestForNutrition from "../pages/KShP/RequestForNutrition";
import Educator from "../pages/KShP/Educator";
import CashAccounting from "../pages/KShP/CashAccounting";
import FoodRation from "../pages/KShP/FoodRation";
import Buffet from "../pages/KShP/Buffet";
import ArmApBuffet from "../pages/KShP/ArmApBuffet";
import MyPayments from "../pages/KShP/MyPayments";
import ChildrenAndParent from "../pages/KShP/ChildrenAndParent";
import ClassesAndTeacher from "../pages/KShP/ClassesAndTeacher";
import LevelEvolutionChildren from "../pages/LevelEvolutionChildren";
import TypePlannedMenu from "../pages/KShP/TypePlannedMenu";
import Benefit from "../pages/KShP/Benefit";
import Status from "../pages/KShP/Status";
import Import from "../pages/KShP/Import";
import CopyBenefit from "../pages/KShP/CopyBenefit";
import SettingsUserKSHP from "../pages/KShP/SettingsUserKSHP";
import Report from "../pages/KShP/Report";
import AgeCategory from "../pages/KShP/AgeCategory";
import SettingsPage from "../pages/admin/SettingsPage";
import StatisticLogin from "../pages/StatisticLogin";
import StatisticLoginKshp from "../pages/KShP/StatisticLoginKshp";
import StatisticLoginKshpArmAp from "../pages/KShP/StatisticLoginKshpArmAp";
import StatisticLoginKshpArmOp from "../pages/KShP/StatisticLoginKshpArmOp";
import PerentPortfolioList from "../pages/parent/PerentPortfolioList";
import PerentPortfolioMore from "../pages/parent/PerentPortfolioMore";
import Game from "../pages/game/Game";
import Feedback from "../pages/Feedback";
import Games from "../pages/game/Games";
import PlayGame from "../pages/game/PlayGame";
import ListGamesTeacher from "../components/GameNew/ListGamesTeacher";
import GameUpdate from "../components/GameNew/GameUpdate";
import SettingsPortfolio from "../pages/admin/SettingsPortfolio";
import OpenGamePrivate from "../components/GameNew/OpenGamePrivate";
import GameStatistics from "../components/GameNew/GameStatistics";
import JournalVentilationRoom from "../pages/journalHaccp/JournalVentilationRoom";
import MeasureTemperaturesRoom from "../pages/journalHaccp/MeasureTemperaturesRoom";
import JournalGeneralCleaning from "../pages/journalHaccp/JournalGeneralCleaning";
import JournalMorningFilterEmployee from "../pages/journalHaccp/JournalMorningFilterEmployee";
import JournalMorningFilterChildren from "../pages/journalHaccp/JournalMorningFilterChildren";
import JournalReplacementBedLinen from "../pages/journalHaccp/JournalReplacementBedLinen";
import PlayGameParent from "../pages/game/PlayGameParent";
import JournalMeasuringTemperatureBody from "../pages/journalHaccp/JournalMeasuringTemperatureBody";
import JournalAntisepticTableware from "../pages/journalHaccp/JournalAntisepticTableware";
import JournalAntisepticSurface from "../pages/journalHaccp/JournalAntisepticSurface";
import JournalAntisepticRoom from "../pages/journalHaccp/JournalAntisepticRoom";
import PurchaseMaterials from "../pages/PurchaseMaterials";
import Shift from "../pages/KShP/Shift";
import NewsMore from "../components/news/NewsMore";
import JournalBactericidalLamps from "../pages/journalHaccp/JournalBactericidalLamps";
import JournalCleaningDisinfection from "../pages/journalHaccp/JournalCleaningDisinfection";
import Position from "../pages/education/Position";
import TypeEducation from "../pages/education/TypeEducation";
import QualificationLevels from "../pages/education/QualificationLevels";
import TypeEvents from "../pages/education/TypeEvents";
import DirectionEducation from "../pages/education/DirectionEducation";
import ReasonsInspections from "../pages/education/ReasonsInspections";
import TypeNDT from "../pages/education/TypeNDT";
import PeriodicityEvents from "../pages/education/PeriodicityEvents";
import TypeWorkPermits from "../pages/education/TypeWorkPermits";
import MainEducation from "../pages/education/MainEducation";
import PlaningEducation from "../pages/education/PlaningEducation";
import CreateTestTasks from "../pages/education/CreateTestTasks";
import TestsPage from "../pages/education/TestsPage";
import EducationPage from "../pages/education/EducationPage";
import CreateEducationContent from "../pages/education/CreateEducationContent";
import TestingCenterDetails from "../pages/education/TestingCenterDetails";

interface IRoutes {
    path: string,
    component: JSX.Element
}
export const PublicRoutes: IRoutes[] = [
    {path: ConstRoutes.LOGIN, component: <Login/>},
    {path: ConstRoutes.GAME_PLAY_PRIVATE, component: <OpenGamePrivate/>}
]

export const FormRoutes: IRoutes[] = [
    {path: ConstRoutes.SUBMISSION_DOCUMENTS, component: <SubmissionDocuments/>},
]

export const PrivateRoutes: IRoutes[] = [
    {path: ConstRoutes.ADMIN_SETTINGS_USER, component: <SettingsUser/>},
    {path: ConstRoutes.ADMIN_SETTINGS_CITY, component: <SettingsCity/>},
    {path: ConstRoutes.ADMIN_SETTINGS_COUNTRY, component: <SettingsCountry/>},
    {path: ConstRoutes.ADMIN_SETTINGS_ORGANIZATION, component: <SettingsOrganization/>},
    {path: ConstRoutes.ADMIN_SETTINGS_POSITION, component: <SettingsPosition/>},
    {path: ConstRoutes.ADMIN_SETTINGS_TERM, component: <SettingsTerm/>},
    {path: ConstRoutes.ADMIN_SETTINGS_PORTFOLIO, component: <SettingsPortfolio/>},
    {path: ConstRoutes.DEPARTMENT_SETTINGS_CABINET, component: <SettingsCabinet/>},
    {path: ConstRoutes.DEPARTMENT_SETTINGS_TYPE_CABINET, component: <SettingsTypeCabinet/>},
    {path: ConstRoutes.DEPARTMENT_SETTINGS_CATEGORY_OVZ, component: <SettingsCategoryOvz/>},
    {path: ConstRoutes.DEPARTMENT_SETTINGS_TYPE_GROUP, component: <SettingsTypeGroup/>},
    {path: ConstRoutes.DEPARTMENT_SETTINGS_TYPE_DEVIATION, component: <SettingsTypeDeviation/>},
    {path: ConstRoutes.DEPARTMENT_SETTINGS_SUBDIVISION, component: <SettingsSubdivision/>},
    {path: ConstRoutes.DEPARTMENT_SETTINGS_REGION, component: <SettingsRegion/>},
    {path: ConstRoutes.DEPARTMENT_SETTINGS_RECOMMENDED_ACTIVE, component: <SettingsRecommendedActive/>},
    {path: ConstRoutes.DEPARTMENT_SETTINGS_GROUP, component: <SettingsGroup/>},
    {path: ConstRoutes.DEPARTMENT_SETTINGS_GROUP_HEALTH, component: <SettingsGroupHealth/>},
    {path: ConstRoutes.DEPARTMENT_SETTINGS_LIMIT_HEALTH, component: <SettingsLimitHealth/>},
    {path: ConstRoutes.DEPARTMENT_SETTINGS_EMPLOYEE, component: <SettingsEmployee/>},
    {path: ConstRoutes.DEPARTMENT_SETTINGS_DO_GROUP, component: <SettingsDoGroup/>},
    {path: ConstRoutes.DEPARTMENT_SETTINGS_CHILDREN, component: <SettingsChildren/>},
    {path: ConstRoutes.DEPARTMENT_SETTINGS_PARENT, component: <SettingsParent/>},
    {path: ConstRoutes.DEPARTMENT_SETTINGS_DEVIATION, component: <SettingsDeviation/>},
    {path: ConstRoutes.DEPARTMENT_SETTINGS_CHILDREN_MOVEMENT_REGISTER, component: <SettingsChildrenMovementRegister/>},
    {path: ConstRoutes.DEPARTMENT_ATTENDANCE_IN_ORGANIZATION, component: <AttendanceInOrganizations/>},
    {path: ConstRoutes.DEPARTMENT_DEVIATIONS_CHILDREN_IN_ORGANIZATION, component: <DeviationsChildrenInOrganization/>},
    {path: ConstRoutes.DEPARTMENT_RECOMMENDED_LESSONS_IN_ORGANIZATIONS, component: <RecommendedLessonsInOrganizations/>},
    {path: ConstRoutes.DEPARTMENT_OVZ_DISABLED_PERSONS_PMPK, component: <OVZDisabledPersonPMPK/>},
    {path: ConstRoutes.DEPARTMENT_OVZ_DISABLED_PERSONS_PMPK_YEAR, component: <OVZDisabledPersonPMPKYear/>},
    {path: ConstRoutes.DEPARTMENT_CERTIFICATE_OVZ_DISABLED_PERSONS, component: <CertificateOvzDisabledPersons/>},
    {path: ConstRoutes.DEPARTMENT_OVZ_DISABLED_PERSONS, component: <OvzInv/>},
    {path: ConstRoutes.DEPARTMENT_PHYSICAL_EDUCATION, component: <CountChildrenPhysicalEducation/>},
    {path: ConstRoutes.DEPARTMENT_GROUP_FOCUSES, component: <GroupFocuses/>},
    {path: ConstRoutes.DEPARTMENT_ADDITIONAL_EDUCATION_IN_PRE_SCHOOL, component: <AdditionalEducationInPreSchool/>},
    {path: ConstRoutes.DEPARTMENT_GROUP_HEALTH, component: <GroupHealth/>},
    {path: ConstRoutes.DEPARTMENT_TALENTED_CHILDREN, component: <TalentedChildren/>},
    {path: ConstRoutes.DEPARTMENT_ACHIEVEMENT_CHILDREN, component: <AchievementsChildren/>},
    {path: ConstRoutes.DEPARTMENT_ACHIEVEMENT_CHILDREN_DEVIATION, component: <AchievementsChildrenDeviations/>},
    {path: ConstRoutes.DEPARTMENT_PROGRAM, component: <ProgramsOOPDOY/>},
    {path: ConstRoutes.DEPARTMENT_ATTENDANCE, component: <AttendanceInOrganizationDOYKindergarten/>},
    {path: ConstRoutes.DEPARTMENT_TEACHERS, component: <Teachers/>},
    {path: ConstRoutes.DEPARTMENT_VSK, component: <VSK/>},
    {path: ConstRoutes.DEPARTMENT_SAFETY, component: <Safety/>},
    {path: ConstRoutes.DEPARTMENT_APR, component: <APR/>},
    {path: ConstRoutes.DEPARTMENT_COMPETITIONS, component: <Competitions/>},
    {path: ConstRoutes.DEPARTMENT_COURSES, component: <Courses/>},
    {path: ConstRoutes.DEPARTMENT_MTB, component: <MTB/>},
    {path: ConstRoutes.DEPARTMENT_SOCIAL, component: <Social/>},
    {path: ConstRoutes.DEPARTMENT_NUTRITION, component: <Nutrition/>},
    {path: ConstRoutes.DEPARTMENT_NUTRITION_DIET, component: <NutritionDiet/>},
    {path: ConstRoutes.DEPARTMENT_METHODOLOGICAL_WORK, component: <MethodologicalWork/>},
    {path: ConstRoutes.DEPARTMENT_PEDAGOGICAL_CONTROL, component: <PedagogicalControl/>},
    {path: ConstRoutes.DEPARTMENT_MEDICAL_CONTROL, component: <MedicalControl/>},
    {path: ConstRoutes.DEPARTMENT_ADMINISTRATIVE_CONTROL, component: <AdministrativeControl/>},
    {path: ConstRoutes.MAIN_PAGE, component: <MainPage/>},
    {path: ConstRoutes.MAIN_PAGE_DO, component: <MainPaidService/>},
    {path: ConstRoutes.OPERATIONAL_CONTROL, component: <OperationalControl/>},
    {path: ConstRoutes.EMPLOYEE_PERFOMANCE, component: <EmployeePerfomance/>},
    {path: ConstRoutes.HELP_PAGE, component: <HelpPage/>},
    {path: ConstRoutes.SETTINGS, component: <Settings/>},
    {path: ConstRoutes.PARENT_CALENDAR, component: <Calendar/>},
    {path: ConstRoutes.REGISTER_DEFECT_EQUIPMENT, component: <RegisterDefectEquipment/>},
    {path: ConstRoutes.PURCHASE_MATERIALS, component: <PurchaseMaterials/>},
    {path: ConstRoutes.PARENT_CHILDREN_ATTENDANCE, component: <ChildrenAttendance/>},
    {path: ConstRoutes.PARENT_CHILDREN_ATTENDANCE_PAID, component: <ChildrenAttendancePaid/>},
    {path: ConstRoutes.PARENT_CHILDREN_EVOLUTION, component: <ChildrenEvolution/>},
    {path: ConstRoutes.PARENT_CHILDREN_FOOD_RATION, component: <ChildrenFoodRation/>},
    {path: ConstRoutes.PARENT_CHILDREN_CASH_ACCOUNTING, component: <ChildrenCashAccounting/>},
    {path: ConstRoutes.PARENT_CHILDREN_PORTFOLIO, component: <ChildrenPortfolio/>},
    {path: ConstRoutes.PARENT_CHILDREN_PORTFOLIO_MORE, component: <ChildrenPortfolioMore/>},
    {path: ConstRoutes.PROFILE_GUEST, component: <ProfileGuest/>},
    {path: ConstRoutes.PROFILE, component: <MyProfile/>},
    {path: ConstRoutes.CHILDREN_PORTFOLIO, component: <KindergartenChildrenPortfolio/>},
    {path: ConstRoutes.CHILDREN_PORTFOLIO_UPDATE, component: <KindergartenChildrenPortfolioMore/>},
    {path: ConstRoutes.CHILDREN_PORTFOLIO_ADD, component: <KindergartenChildrenPortfolioAdd/>},
    {path: ConstRoutes.PARENT_PORTFOLIO_LIST, component: <PerentPortfolioList/>},
    {path: ConstRoutes.PARENT_PORTFOLIO_MORE, component: <PerentPortfolioMore/>},
    {path: ConstRoutes.GROUP_NEWS, component: <News/>},
    {path: ConstRoutes.GROUP_NEWS_ADD, component: <NewsAdd/>},
    {path: ConstRoutes.GROUP_NEWS_UPDATE, component: <NewsUpdate/>},
    {path: ConstRoutes.PARENT_CHILDREN_NEWS, component: <ParentNews/>},
    {path: ConstRoutes.PARENT_CHILDREN_NEWS_MORE, component: <ParentNewsMore/>},
    {path: ConstRoutes.NEWS_CHILDREN_NEWS_MORE, component: <NewsMore/>},
    {path: ConstRoutes.DAILY_REPORT, component: <DailyReport/>},
    {path: ConstRoutes.CHAT, component: <Chat/>},
    {path: ConstRoutes.FEEDBACK, component: <Feedback/>},
    {path: ConstRoutes.TASK_ME, component: <MyTask/>},
    {path: ConstRoutes.TASK_FROM_ME, component: <FromMyTask/>},
    {path: ConstRoutes.MY_EVENT, component: <MyEvent/>},
    {path: ConstRoutes.SEMINAR, component: <Seminar/>},
    {path: ConstRoutes.CATERING_ORGANIZERS, component: <CateringOrganizers/>},
    {path: ConstRoutes.CATERING_ORGANIZERS_ADD, component: <AddCateringOrganizers/>},
    {path: ConstRoutes.TYPES_NUTRITION, component: <TypesNutrition/>},
    {path: ConstRoutes.SCHOOLS, component: <Schools/>},
    {path: ConstRoutes.SEMINAR_ADMIN, component: <SeminarAdmin/>},
    {path: ConstRoutes.STUDENTS, component: <Students/>},
    {path: ConstRoutes.HOME_EDUCATION, component: <HomeEducation/>},
    {path: ConstRoutes.ASSORTMENT, component: <Assortment/>},
    {path: ConstRoutes.COMPLEX_MENU, component: <ComplexMenu/>},
    {path: ConstRoutes.BALANCE, component: <Balance/>},
    {path: ConstRoutes.REQUEST_FOR_NUTRITION, component: <RequestForNutrition/>},
    {path: ConstRoutes.CASH_MOVEMENT, component: <CashMovement/>},
    {path: ConstRoutes.ARM_EDUCATOR, component: <Educator/>},
    {path: ConstRoutes.ARM_PARENT_CASH_ACCOUNTING, component: <CashAccounting/>},
    {path: ConstRoutes.ARM_PARENT_FOOD_RATION, component: <FoodRation/>},
    {path: ConstRoutes.ARM_PARENT_BUFFET, component: <Buffet/>},
    {path: ConstRoutes.BUFFET, component: <ArmApBuffet/>},
    {path: ConstRoutes.ARM_PARENT_MY_PAYMENTS, component: <MyPayments/>},
    {path: ConstRoutes.CHILDREN_AND_PARENT, component: <ChildrenAndParent/>},
    {path: ConstRoutes.CLASSES_AND_TEACHER, component: <ClassesAndTeacher/>},
    {path: ConstRoutes.LEVEL_EVOLUTION_CHILDREN, component: <LevelEvolutionChildren/>},
    {path: ConstRoutes.TYPE_PLANNED_MENU, component: <TypePlannedMenu/>},
    {path: ConstRoutes.BENEFIT, component: <Benefit/>},
    {path: ConstRoutes.STATUSES, component: <Status/>},
    {path: ConstRoutes.IMPORTS, component: <Import/>},
    {path: ConstRoutes.COPY_BENEFITS, component: <CopyBenefit/>},
    {path: ConstRoutes.SETTINGS_USER, component: <SettingsUserKSHP/>},
    {path: ConstRoutes.REPORT, component: <Report/>},
    {path: ConstRoutes.AGE_CATEGORIES, component: <AgeCategory/>},
    {path: ConstRoutes.SHIFT, component: <Shift/>},
    {path: ConstRoutes.ADMIN_SETTINGS_PAGE, component: <SettingsPage/>},
    {path: ConstRoutes.STATISTICS_LOGIN, component: <StatisticLogin/>},
    {path: ConstRoutes.STATISTICS_LOGIN_KSHP, component: <StatisticLoginKshp/>},
    {path: ConstRoutes.STATISTICS_LOGIN_KSHP_ARM_AP, component: <StatisticLoginKshpArmAp/>},
    {path: ConstRoutes.STATISTICS_LOGIN_KSHP_ARM_OP, component: <StatisticLoginKshpArmOp/>},
    {path: ConstRoutes.GAME, component: <Game/>},
    {path: ConstRoutes.GAMES, component: <Games/>},
    {path: ConstRoutes.PLAY_GAME, component: <PlayGame/>},
    {path: ConstRoutes.GAMES_TEACHER, component: <ListGamesTeacher/>},
    {path: ConstRoutes.GAMES_UPDATE, component: <GameUpdate/>},
    {path: ConstRoutes.MEASURE_TEMPERATURES, component: <MeasureTemperaturesRoom/>},
    {path: ConstRoutes.JOURNAL_VENTILATION, component: <JournalVentilationRoom/>},
    {path: ConstRoutes.JOURNAL_GENERAL_CLEANING, component: <JournalGeneralCleaning/>},
    {path: ConstRoutes.JOURNAL_MORNING_FILTER_EMPLOYEES, component: <JournalMorningFilterEmployee/>},
    {path: ConstRoutes.JOURNAL_MORNING_FILTER_CHILDREN, component: <JournalMorningFilterChildren/>},
    {path: ConstRoutes.JOURNAL_REPLACEMENT_BED_LINER, component: <JournalReplacementBedLinen/>},
    {path: ConstRoutes.JOURNAL_MEASURING_TEMPERATURE_BODY, component: <JournalMeasuringTemperatureBody/>},
    {path: ConstRoutes.JOURNAL_ANTISEPTIC_TABLEWARE, component: <JournalAntisepticTableware/>},
    {path: ConstRoutes.JOURNAL_ANTISEPTIC_SURFACE, component: <JournalAntisepticSurface/>},
    {path: ConstRoutes.JOURNAL_ANTISEPTIC_ROOM, component: <JournalAntisepticRoom/>},
    {path: ConstRoutes.JOURNAL_BACTERICIDAL_LAMPS, component: <JournalBactericidalLamps/>},
    {path: ConstRoutes.JOURNAL_CLEANING_DISINFECTION, component: <JournalCleaningDisinfection/>},
    {path: ConstRoutes.GAME_STATISTICS, component: <GameStatistics/>},
    {path: ConstRoutes.PLAY_GAME_PARENT, component: <PlayGameParent/>},
    {path: ConstRoutes.EDUCATION_POSITION, component: <Position/>},
    {path: ConstRoutes.EDUCATION_TYPE_OF_EDUCATION, component: <TypeEducation/>},
    {path: ConstRoutes.EDUCATION_QUALIFICATION_LEVELS, component: <QualificationLevels/>},
    {path: ConstRoutes.EDUCATION_TYPE_OF_EVENTS, component: <TypeEvents/>},
    {path: ConstRoutes.EDUCATION_DIRECTION_EDUCATION, component: <DirectionEducation/>},
    {path: ConstRoutes.EDUCATION_REASONS_INSPECTIONS, component: <ReasonsInspections/>},
    {path: ConstRoutes.EDUCATION_TYPE_NDT, component: <TypeNDT/>},
    {path: ConstRoutes.EDUCATION_PERIODICITY_EVENTS, component: <PeriodicityEvents/>},
    {path: ConstRoutes.EDUCATION_TYPE_WORK_PERMITS, component: <TypeWorkPermits/>},
    {path: ConstRoutes.EDUCATION_MAIN, component: <MainEducation/>},
    {path: ConstRoutes.EDUCATION_PLANING, component: <PlaningEducation/>},
    {path: ConstRoutes.EDUCATION_CREATE_TEST_TASKS, component: <CreateTestTasks/>},
    {path: ConstRoutes.EDUCATION_TESTS_PAGE, component: <TestsPage/>},
    {path: ConstRoutes.EDUCATION_EDUCATION_PAGE, component: <EducationPage/>},
    {path: ConstRoutes.EDUCATION_CREATE_EDUCATION_CONTENT, component: <CreateEducationContent/>},
    {path: ConstRoutes.EDUCATION_CREATE_TESTING_CENTER, component: <TestingCenterDetails/>},
]