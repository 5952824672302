import React, { FC, useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from "reactstrap";
import DataTable, { TableColumn } from "react-data-table-component";

interface ITest {
    id: number;
    name: string;
    periods: (string | null)[];
}

interface IModalAddTestProps {
    isOpen: boolean;
    toggle: () => void;
    availableTests: ITest[];
    onSave: (selectedTests: ITest[]) => void;
    attachedTests: ITest[];
}

const ModalAddTest: FC<IModalAddTestProps> = ({ isOpen, toggle, availableTests, onSave, attachedTests }) => {
    const [selectedTests, setSelectedTests] = useState<ITest[]>([]);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        if (isOpen) {
            setSelectedTests(attachedTests);
        }
    }, [isOpen, attachedTests]);

    const filteredTests = availableTests.filter(test =>
        test.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleSelectTest = (test: ITest, isChecked: boolean) => {
        if (isChecked) {
            setSelectedTests(prev => [...prev, test]);
        } else {
            setSelectedTests(prev => prev.filter(item => item.id !== test.id));
        }
    };

    const handleSave = () => {
        onSave(selectedTests);
        setSelectedTests([]);
    };

    const columns: TableColumn<ITest>[] = [
        {
            name: <span className="font-weight-bold d-flex justify-content-center">Выбрать</span>,
            cell: (row: ITest) => (
                <div className="d-flex justify-content-center">
                    <Input
                        type="checkbox"
                        className="fs-16"
                        checked={selectedTests.some(item => item.id === row.id)}
                        onChange={(e) => handleSelectTest(row, e.target.checked)}
                    />
                </div>
            ),
            width: '100px',
        },
        {
            name: <span className="font-weight-bold">Название</span>,
            selector: (row: ITest) => row.name,
            wrap: true,
            minWidth: '150px',
            maxWidth: '300px',
        },
    ];

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            modalClassName="zoomIn"
            tabIndex={-1}
            centered
            size="lg"
        >
            <ModalHeader toggle={toggle} className="p-3 bg-soft-primary">
                Добавить тест
            </ModalHeader>
            <ModalBody>
                <div className="mb-3">
                    <Input
                        type="text"
                        className="form-control"
                        placeholder="Поиск по названию"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>
                <DataTable
                    columns={columns}
                    data={filteredTests}
                    noDataComponent="Нет доступных тестов"
                    highlightOnHover
                    customStyles={{
                        headCells: {
                            style: {
                                minWidth: '80px',
                            },
                        },
                        table: {
                            style: {
                                width: '100%',
                            },
                        },
                    }}
                />
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleSave}>
                    Добавить
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalAddTest;