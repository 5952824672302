import React, { useEffect, useState, useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Alert, Card, CardBody, CardHeader, Container, Nav, NavItem, NavLink, TabContent, TabPane, Button } from "reactstrap";
import { Helmet } from "react-helmet";
import classNames from "classnames";
import DataTable, { TableColumn } from "react-data-table-component";
import ModalAddEducation from "../../components/education/PlaningEducation/modal/ModalAddEducation";
import ModalAddTest from "../../components/education/PlaningEducation/modal/ModalAddTest";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import { getPositions, setSelectPosition } from "../../store/reducers/admin/ActionCreator";
import SimpleBar from "simplebar-react";
import { IPosition } from "../../models/IPosition";
import { Link } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru";

interface IEducation {
    id: number;
    name: string;
    periods: (string | null)[];
}

interface ITest {
    id: number;
    name: string;
    periods: (string | null)[];
}

interface IPeriodRow {
    type: 'period';
    parentId: number;
    period: string | null;
    index: number;
}

type TableRow = IEducation | ITest | IPeriodRow;

// Интерфейс для данных, связанных с должностью
interface IPositionData {
    educations: IEducation[];
    tests: ITest[];
}

const TestingCenterDetails: React.FC = () => {
    const { centerId } = useParams<{ centerId: string }>();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { isError, positions } = useAppSelector(state => state.AdminReducer);

    const [centerNameState, setCenterNameState] = useState<string>('Неизвестный центр');
    const [activeTab, setActiveTab] = useState("1");
    const [currentPositionId, setCurrentPositionId] = useState<number>(0);
    const [modalEducationOpen, setModalEducationOpen] = useState(false);
    const [modalTestOpen, setModalTestOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");

    // Состояние для данных, связанных с каждой должностью
    const [positionData, setPositionData] = useState<{ [positionId: number]: IPositionData }>({});

    // Доступные обучения и тесты (заглушка)
    const availableEducations: IEducation[] = [
        { id: 1, name: "Профессия HR", periods: [] },
        { id: 2, name: "База для HR", periods: [] },
        { id: 3, name: "Структуры данных", periods: [] },
    ];

    const availableTests: ITest[] = [
        { id: 1, name: "Тест по HR", periods: [] },
        { id: 2, name: "Тест по разработке", periods: [] },
        { id: 3, name: "Тест по продажам", periods: [] },
    ];

    useEffect(() => {
        const initialCenterName = (location.state as { centerName: string } | undefined)?.centerName || 'Неизвестный центр';
        setCenterNameState(initialCenterName);
    }, []);

    useEffect(() => {
        dispatch(getPositions());
    }, [dispatch]);

    useEffect(() => {
        if (positions.length > 0) {
            setCurrentPositionId(positions[0].id);
            dispatch(setSelectPosition(positions[0]));
            initializePositionData(positions[0].id); // Инициализируем данные для первой должности
        }
    }, [positions, dispatch]);

    // Инициализация данных для новой должности, если их ещё нет
    const initializePositionData = (positionId: number) => {
        if (!positionData[positionId]) {
            setPositionData(prev => ({
                ...prev,
                [positionId]: {
                    educations: [],
                    tests: [],
                },
            }));
        }
    };

    const positionUpdate = (position: IPosition) => {
        setCurrentPositionId(position.id);
        dispatch(setSelectPosition(position));
        initializePositionData(position.id); // Инициализируем данные для новой должности
    };

    const filteredPositions = positions.filter(position =>
        position.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const tabChange = (tab: string) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const currentPositionData = positionData[currentPositionId] || { educations: [], tests: [] };
    const attachedEducations = currentPositionData.educations;
    const attachedTests = currentPositionData.tests;

    // добавлениe и удалениe периодов в таблицах
    const handleAddPeriodEducation = (educationId: number) => {
        setPositionData(prev => ({
            ...prev,
            [currentPositionId]: {
                ...prev[currentPositionId],
                educations: prev[currentPositionId].educations.map(education =>
                    education.id === educationId
                        ? { ...education, periods: [...education.periods, null] }
                        : education
                ),
            },
        }));
    };

    const handleUpdatePeriodEducation = (educationId: number, periodIndex: number, newPeriod: string) => {
        setPositionData(prev => ({
            ...prev,
            [currentPositionId]: {
                ...prev[currentPositionId],
                educations: prev[currentPositionId].educations.map(education =>
                    education.id === educationId
                        ? {
                            ...education,
                            periods: education.periods.map((p, index) =>
                                index === periodIndex ? newPeriod : p
                            ),
                        }
                        : education
                ),
            },
        }));
    };

    const handleRemovePeriodEducation = (educationId: number, periodIndex: number) => {
        const educationExists = attachedEducations.some(edu => edu.id === educationId);
        if (!educationExists) return;

        setPositionData(prev => ({
            ...prev,
            [currentPositionId]: {
                ...prev[currentPositionId],
                educations: prev[currentPositionId].educations.map(education =>
                    education.id === educationId
                        ? {
                            ...education,
                            periods: education.periods.filter((_, index) => index !== periodIndex),
                        }
                        : education
                ),
            },
        }));
    };

    const handleRemoveEducation = (educationId: number) => {
        setPositionData(prev => ({
            ...prev,
            [currentPositionId]: {
                ...prev[currentPositionId],
                educations: prev[currentPositionId].educations.filter(education => education.id !== educationId),
            },
        }));
    };

    const handleAddPeriodTest = (testId: number) => {
        setPositionData(prev => ({
            ...prev,
            [currentPositionId]: {
                ...prev[currentPositionId],
                tests: prev[currentPositionId].tests.map(test =>
                    test.id === testId ? { ...test, periods: [...test.periods, null] } : test
                ),
            },
        }));
    };

    const handleUpdatePeriodTest = (testId: number, periodIndex: number, newPeriod: string) => {
        setPositionData(prev => ({
            ...prev,
            [currentPositionId]: {
                ...prev[currentPositionId],
                tests: prev[currentPositionId].tests.map(test =>
                    test.id === testId
                        ? {
                            ...test,
                            periods: test.periods.map((p, index) =>
                                index === periodIndex ? newPeriod : p
                            ),
                        }
                        : test
                ),
            },
        }));
    };

    const handleRemovePeriodTest = (testId: number, periodIndex: number) => {
        const testExists = attachedTests.some(test => test.id === testId);
        if (!testExists) return;

        setPositionData(prev => ({
            ...prev,
            [currentPositionId]: {
                ...prev[currentPositionId],
                tests: prev[currentPositionId].tests.map(test =>
                    test.id === testId
                        ? {
                            ...test,
                            periods: test.periods.filter((_, index) => index !== periodIndex),
                        }
                        : test
                ),
            },
        }));
    };

    const handleRemoveTest = (testId: number) => {
        setPositionData(prev => ({
            ...prev,
            [currentPositionId]: {
                ...prev[currentPositionId],
                tests: prev[currentPositionId].tests.filter(test => test.id !== testId),
            },
        }));
    };

    const handleSaveEducations = (selectedEducations: IEducation[]) => {
        setPositionData(prev => ({
            ...prev,
            [currentPositionId]: {
                ...prev[currentPositionId],
                educations: selectedEducations,
            },
        }));
        setModalEducationOpen(false);
    };

    const handleSaveTests = (selectedTests: ITest[]) => {
        setPositionData(prev => ({
            ...prev,
            [currentPositionId]: {
                ...prev[currentPositionId],
                tests: selectedTests,
            },
        }));
        setModalTestOpen(false);
    };

    // Формируем данные для таблицы обучений (с подстроками для периодов) с помощью useMemo
    const educationTableData: TableRow[] = useMemo(() => {
        const tableData: TableRow[] = [];
        attachedEducations.forEach(education => {
            tableData.push(education);
            education.periods.forEach((period, index) => {
                tableData.push({
                    type: 'period',
                    parentId: education.id,
                    period,
                    index,
                });
            });
        });
        return tableData;
    }, [attachedEducations]);

    // для таблицы тестов
    const testTableData: TableRow[] = useMemo(() => {
        const tableData: TableRow[] = [];
        attachedTests.forEach(test => {
            tableData.push(test);
            test.periods.forEach((period, index) => {
                tableData.push({
                    type: 'period',
                    parentId: test.id,
                    period,
                    index,
                });
            });
        });
        return tableData;
    }, [attachedTests]);

    const educationColumns: TableColumn<TableRow>[] = [
        {
            name: <span className="font-weight-bold">Название</span>,
            cell: (row: TableRow) => {
                if ('type' in row) {
                    return <span style={{ marginLeft: '40px' }}>{row.period || 'Период не выбран'}</span>;
                }
                return <span>{(row as IEducation).name}</span>;
            },
            wrap: true,
            minWidth: '150px',
        },
        {
            name: <span className="font-weight-bold">Период</span>,
            cell: (row: TableRow) => {
                if ('type' in row) {
                    const periodRow = row as IPeriodRow;
                    return (
                        <Flatpickr
                            value={
                                periodRow.period
                                    ? periodRow.period
                                        .split(' - ')
                                        .map(date => new Date(date.split('.').reverse().join('-')))
                                    : []
                            }
                            options={{
                                locale: Russian,
                                mode: 'range',
                                dateFormat: "d.m.Y",
                            }}
                            onChange={(dates) => {
                                if (dates.length === 2) {
                                    const startDate = dates[0].toLocaleDateString('ru-RU');
                                    const endDate = dates[1].toLocaleDateString('ru-RU');
                                    handleUpdatePeriodEducation(
                                        periodRow.parentId,
                                        periodRow.index,
                                        `${startDate} - ${endDate}`
                                    );
                                }
                            }}
                            placeholder="Выберите диапазон"
                            className="form-control"
                        />
                    );
                }
                const education = row as IEducation;
                return (
                    <Button
                        color="primary"
                        size="sm"
                        onClick={() => handleAddPeriodEducation(education.id)}
                    >
                        Добавить период
                    </Button>
                );
            },
            width: '300px',
        },
        {
            name: <span className="font-weight-bold">Удалить</span>,
            cell: (row: TableRow) => {
                if ('type' in row) {
                    const periodRow = row as IPeriodRow;
                    return (
                        <i
                            className="bi bi-trash fs-5 text-danger cursor-pointer"
                            onClick={() =>
                                handleRemovePeriodEducation(periodRow.parentId, periodRow.index)
                            }
                        />
                    );
                }
                const education = row as IEducation;
                return (
                    <i
                        className="bi bi-trash fs-5 text-danger cursor-pointer"
                        onClick={() => handleRemoveEducation(education.id)}
                    />
                );
            },
            width: '90px',
        },
    ];

    const testColumns: TableColumn<TableRow>[] = [
        {
            name: <span className="font-weight-bold">Название</span>,
            cell: (row: TableRow) => {
                if ('type' in row) {
                    return <span style={{ marginLeft: '40px' }}>{row.period || 'Период не выбран'}</span>;
                }
                return <span>{(row as ITest).name}</span>;
            },
            wrap: true,
            minWidth: '150px',
        },
        {
            name: <span className="font-weight-bold">Период</span>,
            cell: (row: TableRow) => {
                if ('type' in row) {
                    const periodRow = row as IPeriodRow;
                    return (
                        <Flatpickr
                            value={
                                periodRow.period
                                    ? periodRow.period
                                        .split(' - ')
                                        .map(date => new Date(date.split('.').reverse().join('-')))
                                    : []
                            }
                            options={{
                                locale: Russian,
                                mode: 'range',
                                dateFormat: "d.m.Y",
                            }}
                            onChange={(dates) => {
                                if (dates.length === 2) {
                                    const startDate = dates[0].toLocaleDateString('ru-RU');
                                    const endDate = dates[1].toLocaleDateString('ru-RU');
                                    handleUpdatePeriodTest(
                                        periodRow.parentId,
                                        periodRow.index,
                                        `${startDate} - ${endDate}`
                                    );
                                }
                            }}
                            placeholder="Выберите диапазон"
                            className="form-control"
                        />
                    );
                }
                const test = row as ITest;
                return (
                    <Button
                        color="primary"
                        size="sm"
                        onClick={() => handleAddPeriodTest(test.id)}
                    >
                        Добавить период
                    </Button>
                );
            },
            width: '300px',
        },
        {
            name: <span className="font-weight-bold">Удалить</span>,
            cell: (row: TableRow) => {
                if ('type' in row) {
                    const periodRow = row as IPeriodRow;
                    return (
                        <i
                            className="bi bi-trash fs-5 text-danger cursor-pointer"
                            onClick={() => handleRemovePeriodTest(periodRow.parentId, periodRow.index)}
                        />
                    );
                }
                const test = row as ITest;
                return (
                    <i
                        className="bi bi-trash fs-5 text-danger cursor-pointer"
                        onClick={() => handleRemoveTest(test.id)}
                    />
                );
            },
            width: '90px',
        },
    ];

    return (
        <div className="page-content">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Детали центра тестирования</title>
            </Helmet>
            <Container fluid>
                {isError && <Alert className="alert-danger mb-4">{isError}</Alert>}
                <div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
                    <div className="chat-leftsidebar" style={{ width: '300px' }}>
                        <div className="px-4 pt-4 mb-4">
                            <div className="d-flex align-items-start">
                                <div className="flex-grow-1">
                                    <h5 className="mb-4">Должности ({filteredPositions.length})</h5>
                                </div>
                            </div>
                            <div className="search-box">
                                <input
                                    type="text"
                                    className="form-control bg-light border-light"
                                    placeholder="Поиск должности"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                                <i className="ri-search-2-line search-icon"></i>
                            </div>
                        </div>
                        <SimpleBar className="chat-room-list pt-3" style={{ margin: "-16px 0px 0px" }}>
                            <div className="d-flex align-items-center px-4 mb-2">
                                <div className="flex-grow-1">
                                    <h4 className="mb-0 fs-11 text-muted text-uppercase">
                                        Выберите для изменения данных
                                    </h4>
                                </div>
                            </div>
                            <div className="chat-message-list">
                                <ul className="list-unstyled chat-list chat-user-list users-list" id="userList">
                                    {(filteredPositions || []).map((position) => (
                                        <li
                                            key={position.id}
                                            className={currentPositionId === position.id ? "active" : ""}
                                        >
                                            <Link
                                                to="#"
                                                onClick={() => positionUpdate(position)}
                                            >
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 chat-user-img online align-self-center me-2 ms-0">
                                                        <div className="avatar-xxs">
                                                            <div className="avatar-title rounded-circle bg-primary userprofile">
                                                                {position.name.charAt(0)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1 overflow-hidden">
                                                        <p className="text-truncate mb-0">{position.name}</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </SimpleBar>
                    </div>

                    <div className="w-100 overflow-hidden">
                        <h4 className="mb-3 mx-3">Центр тестирования: {centerNameState}</h4>
                        <Card className="mx-n3">
                            <CardHeader>
                                <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                                    <NavItem>
                                        <NavLink
                                            className={classNames({ active: activeTab === "1" })}
                                            onClick={() => tabChange("1")}
                                            type="button"
                                        >
                                            Обучения
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classNames({ active: activeTab === "2" })}
                                            onClick={() => tabChange("2")}
                                            type="button"
                                        >
                                            Тесты
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </CardHeader>
                            <CardBody className="p-4">
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        <DataTable
                                            key={JSON.stringify(attachedEducations)}
                                            columns={educationColumns}
                                            data={educationTableData}
                                            noDataComponent="Нет прикреплённых обучений"
                                            pagination
                                            highlightOnHover
                                            customStyles={{
                                                headCells: {
                                                    style: {
                                                        minWidth: '80px',
                                                    },
                                                },
                                                table: {
                                                    style: {
                                                        width: '100%',
                                                    },
                                                },
                                            }}
                                            conditionalRowStyles={[
                                                {
                                                    when: (row: TableRow) => !('type' in row),
                                                    style: {
                                                        backgroundColor: '#f5f5f5',
                                                    },
                                                },
                                            ]}
                                        />
                                        <Button
                                            color="primary"
                                            className="mt-3"
                                            onClick={() => setModalEducationOpen(true)}
                                        >
                                            Добавить обучение
                                        </Button>
                                    </TabPane>

                                    <TabPane tabId="2">
                                        <DataTable
                                            key={JSON.stringify(attachedTests)}
                                            columns={testColumns}
                                            data={testTableData}
                                            noDataComponent="Нет прикреплённых тестов"
                                            pagination
                                            highlightOnHover
                                            customStyles={{
                                                headCells: {
                                                    style: {
                                                        minWidth: '80px',
                                                    },
                                                },
                                                table: {
                                                    style: {
                                                        width: '100%',
                                                    },
                                                },
                                            }}
                                            conditionalRowStyles={[
                                                {
                                                    when: (row: TableRow) => !('type' in row),
                                                    style: {
                                                        backgroundColor: '#f5f5f5',
                                                    },
                                                },
                                            ]}
                                        />
                                        <Button
                                            color="primary"
                                            className="mt-3"
                                            onClick={() => setModalTestOpen(true)}
                                        >
                                            Добавить тест
                                        </Button>
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </Container>

            <ModalAddEducation
                isOpen={modalEducationOpen}
                toggle={() => setModalEducationOpen(false)}
                availableEducations={availableEducations}
                onSave={handleSaveEducations}
                attachedEducations={attachedEducations}
            />

            <ModalAddTest
                isOpen={modalTestOpen}
                toggle={() => setModalTestOpen(false)}
                availableTests={availableTests}
                onSave={handleSaveTests}
                attachedTests={attachedTests}
            />
        </div>
    );
};

export default TestingCenterDetails;