import React, { useState } from 'react';
import { Card, CardBody, CardHeader } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import ModalCreateCenter from './modal/ModalCreateCenter';

interface ITestingCenter {
    id: number;
    name: string;
}

const TestingCenters: React.FC = () => {
    const navigate = useNavigate();

    const [centers, setCenters] = useState<ITestingCenter[]>([
        {
            id: 1,
            name: "Центр тестирования 1",
        },
        {
            id: 2,
            name: "Центр тестирования 2",
        },
        {
            id: 3,
            name: "Центр тестирования 3",
        },
    ]);

    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [centerToEdit, setCenterToEdit] = useState<ITestingCenter | null>(null);

    const handleCenterClick = (centerId: number, centerName: string) => {
        navigate(`/education/testing_center/${centerId}`, { state: { centerName } });
    };

    const handleEditClick = (center: ITestingCenter) => {
        setCenterToEdit(center);
        setIsEditModalOpen(true);
    };

    const handleSaveCenter = (data: { name: string }) => {
        if (centerToEdit) {
            setCenters(centers.map(center =>
                center.id === centerToEdit.id ? { ...center, name: data.name } : center
            ));
        }
        setCenterToEdit(null);
        setIsEditModalOpen(false);
    };

    return (
        <Card>
            <CardHeader className="align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">Центры тестирования</h4>
            </CardHeader>
            <CardBody>
                {centers.map(center => (
                    <div key={center.id} className="d-flex align-items-center mb-3">
                        <div className="flex-grow-1">
                            <h5
                                className="mb-1 cursor-pointer"
                                onClick={() => handleCenterClick(center.id, center.name)}
                            >
                                {center.name}
                            </h5>
                        </div>
                        <button
                            className="btn"
                            title="Редактировать"
                            onClick={() => handleEditClick(center)}
                        >
                            <i className="bi bi-pencil fs-5 text-black" />
                        </button>
                    </div>
                ))}
            </CardBody>
            {/* Модальное окно для редактирования */}
            <ModalCreateCenter
                isOpen={isEditModalOpen}
                toggle={() => setIsEditModalOpen(false)}
                onSave={handleSaveCenter}
                initialData={centerToEdit || undefined}
            />
        </Card>
    );
};

export default TestingCenters;