import React, { FC, useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from "reactstrap";
import DataTable, { TableColumn } from "react-data-table-component";

interface IEducation {
    id: number;
    name: string;
    periods: (string | null)[];
}

interface IModalAddEducationProps {
    isOpen: boolean;
    toggle: () => void;
    availableEducations: IEducation[];
    onSave: (selectedEducations: IEducation[]) => void;
    attachedEducations: IEducation[];
}

const ModalAddEducation: FC<IModalAddEducationProps> = ({ isOpen, toggle, availableEducations, onSave, attachedEducations }) => {
    const [selectedEducations, setSelectedEducations] = useState<IEducation[]>([]);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        if (isOpen) {
            setSelectedEducations(attachedEducations);
        }
    }, [isOpen, attachedEducations]);

    const filteredEducations = availableEducations.filter(education =>
        education.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleSelectEducation = (education: IEducation, isChecked: boolean) => {
        if (isChecked) {
            setSelectedEducations(prev => [...prev, education]);
        } else {
            setSelectedEducations(prev => prev.filter(item => item.id !== education.id));
        }
    };

    const handleSave = () => {
        onSave(selectedEducations);
        setSelectedEducations([]);
    };

    const columns: TableColumn<IEducation>[] = [
        {
            name: <span className="font-weight-bold d-flex justify-content-center">Выбрать</span>,
            cell: (row: IEducation) => (
                <div className="d-flex justify-content-center">
                    <Input
                        type="checkbox"
                        className="fs-16"
                        checked={selectedEducations.some(item => item.id === row.id)}
                        onChange={(e) => handleSelectEducation(row, e.target.checked)}
                    />
                </div>
            ),
            width: '100px',
        },
        {
            name: <span className="font-weight-bold">Название</span>,
            selector: (row: IEducation) => row.name,
            wrap: true,
            minWidth: '150px',
            maxWidth: '300px',
        },
    ];

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            modalClassName="zoomIn"
            tabIndex={-1}
            centered
            size="lg"
        >
            <ModalHeader toggle={toggle} className="p-3 bg-soft-primary">
                Добавить обучение
            </ModalHeader>
            <ModalBody>
                <div className="mb-3">
                    <Input
                        type="text"
                        className="form-control"
                        placeholder="Поиск по названию"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>
                <DataTable
                    columns={columns}
                    data={filteredEducations}
                    noDataComponent="Нет доступных обучений"
                    highlightOnHover
                    customStyles={{
                        headCells: {
                            style: {
                                minWidth: '80px',
                            },
                        },
                        table: {
                            style: {
                                width: '100%',
                            },
                        },
                    }}
                />
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={handleSave}>
                    Добавить
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModalAddEducation;