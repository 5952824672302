import React, {useEffect, useState} from 'react';
import { Helmet } from "react-helmet";
import {
    Col,
    Container,
    Row,
} from "reactstrap";
import { useAppDispatch } from "../../hooks/redux";
import { useStateContext } from "../../hooks/useStateContext";
import RecentTests from "../../components/education/PlaningEducation/RecentTests";
import TestingCenters from "../../components/education/PlaningEducation/TestingCenters";
import ActionBlock from "../../components/education/PlaningEducation/ActionBlock";
import StatsCards from "../../components/education/PlaningEducation/StatsCards";
import ModalGradingScale from "../../components/education/PlaningEducation/modal/ModalGradingScale";
import NotificationShow from "../../components/widgets/NotificationShow";
import RecentEducation from "../../components/education/PlaningEducation/RecentEducation";
import ModalCreateCenter from "../../components/education/PlaningEducation/modal/ModalCreateCenter";
import { useNavigate } from 'react-router-dom';

const PlaningEducation = () => {
    const dispatch = useAppDispatch();
    const { user } = useStateContext();
    const navigate = useNavigate();

    useEffect(() => {
        // добавить логику инициализации
    }, [dispatch]);

    const [modalGradingScaleOpen, setModalGradingScaleOpen] = useState(false);
    const [modalCreateCenterOpen, setModalCreateCenterOpen] = useState(false);

    const toggleGradingScaleModal = () => setModalGradingScaleOpen(!modalGradingScaleOpen);
    const toggleCreateCenterModal = () => setModalCreateCenterOpen(!modalCreateCenterOpen);

    const statsData = [
        { title: "Центров тестирования", value: 3 },
        { title: "Обучений опубликовано", value: 0 },
        { title: "Тестов опубликовано", value: 5 },
    ];

    const handleSaveCenter = (data: { name: string }) => {
        console.log("Сохраненные данные центра тестирования:", data);
    };

    const navigateToCreateTest = () => {
        navigate('/education/test_page');
    };

    const navigateToCreateEducation = () => {
        navigate('/education/education_page');
    };

    const navigateToEditTest = (testData: any) => {
        navigate('/education/test_page', { state: { testData } });
    };

    return (
        <>
            <div className="page-content">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Планирование обучения</title>
                </Helmet>
                <Container fluid>
                    {/* Верхняя полоска с карточками */}
                    <StatsCards stats={statsData} />

                    <Row>
                        <Col md={12}>
                            <TestingCenters />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <RecentTests onEditTest={navigateToEditTest} />
                        </Col>
                        <Col md={6}>
                            <RecentEducation />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}>
                            <ActionBlock
                                icon="bi bi-bar-chart"
                                title="Шкала оценок"
                                description="Настроить уровни оценок в зависимости от набранного балла"
                                onClick={toggleGradingScaleModal}
                            />
                        </Col>
                        <Col md={3}>
                            <ActionBlock
                                icon="bi bi-clipboard-plus"
                                title="Создать тест"
                                description="Инструмент создания теста"
                                onClick={navigateToCreateTest}
                            />
                        </Col>
                        <Col md={3}>
                            <ActionBlock
                                icon="bi bi-file-plus"
                                title="Создать обучение"
                                description="Инструмент создания обучения"
                                onClick={navigateToCreateEducation}
                            />
                        </Col>
                        <Col md={3}>
                            <ActionBlock
                                icon="bi bi-house-add"
                                title="Создать центр тестирования"
                                description="Инструмент создания центра тестирования"
                                onClick={toggleCreateCenterModal}
                            />
                        </Col>
                    </Row>
                </Container>
                <NotificationShow />
                {/* Модальное окно для Шкалы оценок */}
                <ModalGradingScale isOpen={modalGradingScaleOpen} toggle={toggleGradingScaleModal} />

                {/* Модальное окно для Создания центра тестирования */}
                <ModalCreateCenter
                    isOpen={modalCreateCenterOpen}
                    toggle={toggleCreateCenterModal}
                    onSave={handleSaveCenter}
                />
            </div>
        </>
    );
};

export default PlaningEducation;